import React from "react";
import footer2 from "../../assets/images/footer/footer-2.webp";
import footer3 from "../../assets/images/footer/footer-3.webp";
import logo from "../../assets/images/footer/logo.png";
import "../../assets/style/footer.css";
import "../../assets/style/footer.css";
import { Link, NavLink, useNavigate } from "react-router-dom";

export const Footer = () => {
  const navText = [
    { lable: "HOME", to: "/" },
    { lable: "SERVICES", to: "/services" },
    { lable: "ABOUT US", to: "/about" },
    { lable: "POLICY", to: "/policy" },
    { lable: "MEDIA", to: null },
    { lable: "CONTACT", to: "/contact" },
  ];
  const navigate = useNavigate();
  return (
    <>
      <div className="footer-bg py-4 top-space">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-2 col-sm-6">
              <div
                className="p-5 p-sm-4 p-lg-0 text-center"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={logo}
                  onClick={() => navigate("/")}
                  alt="logo"
                  className="img-fluid p-2"
                />
              </div>
            </div>

            <div className="col-lg-2 col-sm-6 mt-4 mt-sm-0">
              <div className="fw-bold text-white mb-3">NAVIGATION</div>
              <div>
                {navText.map((val, ind) => (
                  <NavLink to={val.to} key={ind}>
                    <div className="footer-data">{val.lable}</div>
                  </NavLink>
                ))}
              </div>
            </div>

            <div className="col-lg-2 col-sm-6 mt-4 mt-sm-0">
              <div className="mb-5">
                <div className="fw-bold text-white mb-3">CONTACT</div>
                <div>
                  <Link to={"/contact"}>
                    <div className="footer-data">General Queries</div>
                  </Link>
                  <Link to={"/ncd-queries"}>
                    <div className="footer-data">NCD Queries</div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 mt-4 mt-sm-0">
              <div className="fw-bold text-white mb-3">SCHEMES</div>
              <div>
                <div className="footer-data"> ONE PERCENT LOAN</div>
                <div className="footer-data"> ULTIMATE LOAN</div>
                <div className="footer-data">HIGH VALUE PLUS LOAN</div>
                <div className="footer-data"> HIGH VALUE LOAN</div>
              </div>
            </div>

            <div className="col-lg-2 col-sm-6 mt-4 mt-sm-0">
              <div className="fw-bold text-white mb-3 mb-md-4">
                FOLLOW US ON
              </div>
              <div
                className="d-flex justify-content-between"
                style={{ width: "150px" }}
              >
                <div className="footer-icon">
                  <a href="https://www.facebook.com/share/e68JS2FXPQCJ2ZJu/?mibextid=qi2Omg">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </div>
                <div className="footer-icon">
                  <a href="https://www.instagram.com/easygoldfincorp?utm_source=qr&igsh=d290ZTNyc3g4czk5">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </div>
                <div className="footer-icon">
                  <i className="fa-brands fa-twitter"></i>
                </div>
                <div className="footer-icon">
                  <i className="fa-brands fa-youtube"></i>
                </div>
              </div>
              <div className="footer-not mt-3 mt-md-3">
          <div className='fs-14 text-light'>Reg Office:<br/> Shop No.3, Fast Floor, Shree Hari Complex Near Yogi Chok Circle Varachha, Surat, Gujarat</div>
              </div>
            </div>
          </div>
        </div>
        <hr className={'text-light'}/>
        <div className={'container'}>
          <div className='fs-14 text-light text-center'>Copyright © EASY GOLD FINCORP 2024 . All rights reserved</div>
        </div>

      </div>
    </>
  );
};
